import React, { MouseEvent } from 'react';
import './list-action-button.scss';
import Button from '../Button';
import Icon from '../../icons/Icon';
import Translate from '../../Translate';
import { TIconTypeName } from '../../../../models/general/icon';

interface IListActionProps {
    id: string;
    type?: 'button' | 'text';
    iconTypeName: TIconTypeName;
    translationKey: string;
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
    disabled?: boolean;
}

export default function ListActionButton(props: IListActionProps) {
    const typeName = props.type === 'text' ? 'text' : 'grey';

    return (
        <Button
            id={props.id}
            typeName={typeName}
            className="ListActionButton"
            onClick={props.onClick}
            disabled={props.disabled}
        >
            <Icon typeName={props.iconTypeName} />
            <Translate msg={props.translationKey} />
        </Button>
    );
}
