import React, { ReactNode, PureComponent } from 'react';
import connect from '../../../../../utils/libs/redux/connect';
import { TTypeaheadData } from '../../../../common/input/Typeahead';
import {
    getSelectedSeatCompanyCode, getSelectedCompany,
} from '../../../../../redux/company/selected/selectors';
import {
    getFetchCompanyFunctionsForTypeAheadAsyncInfo,
    getCompanyFunctionsForTypeAhead,
} from '../../../../../redux/company/functions/selectors';
import { ICompanyFunction, IAddedCompanyFunction } from '../../../../../models/admin/companyFunctions';
import {
    fetchCompanyFunctionsForTypeahead,
} from '../../../../../redux/company/functions/actions';
import FreeTextAsyncTypeahead from '../../../../common/input/Typeahead/FreeTextAsyncTypeahead';
import AsyncTypeahead, { IAsyncTypeaheadProps } from '../../../../common/input/Typeahead/AsyncTypeahead';
import Translate from '../../../../common/Translate';
import Icon from '../../../../common/icons/Icon';
import AddFunction from '../AddFunction';
import Dialog from '../../../../common/modals/Dialog';
import { getLocale } from '../../../../../redux/i18n/selectors';
import { Locales } from '../../../../../config/i18n.config';
import { hasPermission } from '../../../../../redux/auth/selectors';
import { Permission } from '../../../../../models/auth/authorisation';

const CLASS_NAME = 'FunctionTypeahead';

interface IPrivateProps {
    companyFunctions: ICompanyFunction[];
    companyCode: string;
    fetchCompanyFunctions: (props: IFetchCompanyFunctionsParams) => void;
    locale: Locales;
    canCreateTempFunction: boolean;
}

interface IFunctionTypeaheadProps {
    id: string;
    name: string;
    value?: string | number;
    initialFilter?: string;
    onItemSelected: (value: number, companyFunction: ICompanyFunction) => void;
    onFilter?: (filter: string) => void;
    showFullFamily: boolean;
    companyCodeOverride?: string;
    placeholder: string;
    isFreeText?: boolean;
    children?: ReactNode;
    currentFunctionDescription?: string;
    isInvalid?: boolean;
    onFunctionAdded?: (companyFunction: IAddedCompanyFunction) => void;
    onToggleAddFunctionDialog?: (open: boolean) => void;
    /* 'immediatelyTriggerSearch' is by default false (so that by default the user has to enter some input
     * before fetching the possibly long list) */
    immediatelyTriggerSearch?: boolean;
}

interface IFetchCompanyFunctionsParams {
    keyword: string;
    companyCodeOverride: string;
    showFullFamily: boolean;
}

interface IState {
    functionsData: TTypeaheadData;
    isAddFunctionDialogOpen: boolean;
}

class FunctionTypeahead extends PureComponent<IFunctionTypeaheadProps & IPrivateProps, IState> {

    constructor(props: IFunctionTypeaheadProps & IPrivateProps) {
        super(props);

        this.state = {
            functionsData: this.mapCompanyFunctionsForTypeahead(props.companyFunctions),
            isAddFunctionDialogOpen: false,
        };

        this.onFunctionFilter = this.onFunctionFilter.bind(this);
        this.onItemSelected = this.onItemSelected.bind(this);
        this.renderAddFunctionButton = this.renderAddFunctionButton.bind(this);
        this.toggleAddFunctionDialog = this.toggleAddFunctionDialog.bind(this);
        this.onFunctionAddedHandler = this.onFunctionAddedHandler.bind(this);
        this.onCloseAddFunctionDialog = this.onCloseAddFunctionDialog.bind(this);
    }

    public render() {
        const {
            children, id, value,
            name, isInvalid,
            placeholder,
            initialFilter, isFreeText,
            onFunctionAdded,
            immediatelyTriggerSearch,
        } = this.props;
        const { functionsData, isAddFunctionDialogOpen } = this.state;

        const commonProps: IAsyncTypeaheadProps = {
            id,
            value,
            name,
            onItemSelected: this.onItemSelected,
            isInvalid,
            onFilter: this.onFunctionFilter,
            asyncInfoSelector: getFetchCompanyFunctionsForTypeAheadAsyncInfo,
            data: functionsData,
            placeholder,
            minCharsToTriggerSearch: (immediatelyTriggerSearch) ? 0 : 1,
            afterSearchFooter: typeof onFunctionAdded === 'function' ? this.renderAddFunctionButton() : undefined,
        };

        return (
            <>
                {isFreeText ? (
                    <FreeTextAsyncTypeahead
                        {...commonProps}
                        initialFilter={initialFilter}
                        autoFilteringDisabled={true}
                    >
                        {children}
                    </FreeTextAsyncTypeahead>
                )
                    : (
                        <AsyncTypeahead {...commonProps}>
                            {children}
                        </AsyncTypeahead>
                    )}
                <Dialog
                    show={isAddFunctionDialogOpen}
                    onCloseIntent={this.onCloseAddFunctionDialog}
                    disableClosing={true}
                >
                    {isAddFunctionDialogOpen && (
                        <AddFunction
                            onClose={this.toggleAddFunctionDialog}
                            onSuccess={this.onFunctionAddedHandler}
                        />
                    )}
                </Dialog>
            </>
        );
    }

    private onFunctionAddedHandler(addedCompanyFunction: IAddedCompanyFunction) {
        const {
            onFunctionAdded, fetchCompanyFunctions, locale, companyCodeOverride, showFullFamily,
        } = this.props;

        const filter =
            locale === Locales.nl_BE ? addedCompanyFunction.descriptionNL : addedCompanyFunction.descriptionFR;

        fetchCompanyFunctions({
            keyword: filter,
            companyCodeOverride,
            showFullFamily,
        });

        this.onItemSelected(addedCompanyFunction.id);
        onFunctionAdded(addedCompanyFunction);
        this.toggleAddFunctionDialog();
    }

    private renderAddFunctionButton() {
        if (!this.props.canCreateTempFunction) {
            return false;
        }

        return (
            <a
                id="function-typeahead-add-function"
                className={`${CLASS_NAME}__add-function`}
                onClick={this.toggleAddFunctionDialog}
            >
                <Icon typeName="plus-circle" />
                <Translate msg="administration.employees.shared.function_typeahead.add" />
            </a>
        );
    }

    private toggleAddFunctionDialog() {
        const { onToggleAddFunctionDialog } = this.props;

        if (typeof onToggleAddFunctionDialog === 'function') {
            this.props.onToggleAddFunctionDialog(!this.state.isAddFunctionDialogOpen);
        }
        this.setState({ isAddFunctionDialogOpen: !this.state.isAddFunctionDialogOpen });
    }

    private onCloseAddFunctionDialog() {
        const { onToggleAddFunctionDialog } = this.props;

        if (typeof onToggleAddFunctionDialog === 'function') {
            this.props.onToggleAddFunctionDialog(false);
        }

        this.setState({ isAddFunctionDialogOpen: false });
    }

    public componentDidMount() {
        this.updateTypeaheadData();

        const { initialFilter, fetchCompanyFunctions, companyCodeOverride, showFullFamily } = this.props;
        if (initialFilter && initialFilter.length > 0) {
            fetchCompanyFunctions({
                keyword: initialFilter,
                companyCodeOverride,
                showFullFamily,
            });
        }
    }

    public componentDidUpdate(prevProps: IFunctionTypeaheadProps & IPrivateProps) {
        if (prevProps.companyFunctions !== this.props.companyFunctions) {
            this.updateTypeaheadData();
        }
    }

    private updateTypeaheadData() {
        const { companyFunctions, value, currentFunctionDescription } = this.props;
        const typeaheadData = this.mapCompanyFunctionsForTypeahead(companyFunctions);

        // Append the current function to the data
        if (value && currentFunctionDescription) {
            typeaheadData.push({
                value: Number(value),
                label: currentFunctionDescription,
            });
        }
        this.setState({
            functionsData: typeaheadData,
        });
    }

    private onFunctionFilter(filter: string) {
        const { fetchCompanyFunctions, onFilter, companyCodeOverride, showFullFamily } = this.props;
        if (typeof onFilter === 'function') {
            onFilter(filter);
        }
        fetchCompanyFunctions({
            keyword: filter,
            companyCodeOverride,
            showFullFamily,
        });
    }

    private mapCompanyFunctionsForTypeahead(companyFunctions: ICompanyFunction[]) {
        return Array.isArray(companyFunctions) ? companyFunctions.map((item) => ({
            value: item.id,
            label: item.description,
        })) : [];
    }

    private onItemSelected(selectedValue: number) {
        const companyFunction = this.props.companyFunctions.find((item) => item.id === selectedValue);
        this.props.onItemSelected(selectedValue, companyFunction || null);
    }
}

export default connect<IPrivateProps, IFunctionTypeaheadProps>({
    statePropsDeprecated: (state, publicProps) => {
        return {
            companyFunctions: getCompanyFunctionsForTypeAhead(state),
            companyCode: getSelectedSeatCompanyCode(state),
            locale: getLocale(state),
            canCreateTempFunction: hasPermission(state, Permission.CAN_CREATE_TEMP_FUNC),
        };
    },
    dispatchProps: (dispatch, getState) => {
        return {
            fetchCompanyFunctions: (params: IFetchCompanyFunctionsParams) => {
                const state = getState();
                const selectedCompanySeatCompanyCode = getSelectedSeatCompanyCode(state);
                const selectedCompany = getSelectedCompany(state);
                const selectedCompanyCompanyCode = selectedCompany && selectedCompany.companyCode;

                const companyCode = params.companyCodeOverride ?
                    params.companyCodeOverride :
                    params.showFullFamily ? selectedCompanyCompanyCode : selectedCompanySeatCompanyCode;

                dispatch(fetchCompanyFunctionsForTypeahead({
                    companyCode,
                    showFullFamily: params.showFullFamily,
                    keyword: params.keyword,
                }));
            },
        };
    },
})(FunctionTypeahead);
